import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import SectionTitle from "./SectionTitle";
import SectionHeading from "./SectionHeading";
import ErrorMsg from "./ErrorMsg";
import Question from "./Question";
import SectionQuestions from "./SectionQuestions";
import NavButtons from "./NavButtons";
import Input from "./Input";

const MultiSection = ({
  id,
  prefix,
  heading,
  extraheading,
  questions,
  updateAnswer,
  answers,
  prompt,
  itemName,
  nextSection,
}) => {
  let fieldPrefix = `${prefix}.${id}`;
  const totalCount = parseInt(answers[fieldPrefix + ".count"]);
  const [preCount, setPreCount] = useState(totalCount);
  const [countError, setCountError] = useState(false);
  const [currentCount, setCurrentCount] = useState(-1);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [errors]);

  const handleNextMulti = () => {
    if (!preCount) {
      setCountError(true);
      return;
    }
    if (preCount === "0" && id === "products") {
      setCountError(true);
      return;
    }
    if(preCount === "0" && id !== "products") {
      setPreCount("0");
      nextSection();
    }
    updateAnswer(fieldPrefix + ".count", preCount);
    setCurrentCount(0);
  };

  const handleMultiCount = newCount => {
    if (newCount > 10) {
      setCountError(true);
      return
    }
    setCountError(false);
    setPreCount(newCount);
  };

  if (!totalCount || currentCount === -1) {
    return (
      <>
        <SectionTitle>{heading}</SectionTitle>
        {extraheading && <SectionHeading>{extraheading}</SectionHeading>}
        <Question index={1} prompt={prompt}>
          <Input
            id="count"
            type="number"
            pattern="[0-9]*"
            placeholder="Select or enter a value..."
            onChange={e => {
              handleMultiCount(e.target.value);
            }}
            value={preCount || ""}
          ></Input>
          {countError && <ErrorMsg>Please enter a number less than 10.</ErrorMsg>}
        </Question>
        <NavButtons next={handleNextMulti} />
      </>
    );
  }

  const handleNextCount = () => {
    let newErrors = {};
    questions.forEach(question => {
      const answer = answers[`${fieldPrefix}.${question.id}`];

      // separate validation for hidden questions
      if (question.visibility !== "") {
        questions.forEach(e => {
          if (e.id === question.visibility.refID) {
            let parentAnswer = answers[`${fieldPrefix}.${e.id}`];
          
            if (parentAnswer === question.visibility.value) {
              if (typeof answer === "undefined" || answer === "") {
                newErrors[question.id] = "This field is required";
                return;
              }
            }
          }
        })

        
      }  
      //eslint-disable-next-line 
      if ((question.type === "url") && !answer.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)) {
          newErrors[question.id] = "Be sure to prepend your web address with 'http://www' or 'https://www'";
      }
      if (Object.keys(question.visibility).length === 0) {
        if (typeof answer === "undefined" || answer === "") {
          newErrors[question.id] = "This field is required";
          return;
        }
      }
      if (answer && (question.type === "number" && !answer.match(/^[\d.]*$/))) {
          newErrors[question.id] = 'Please enter digits only';
      }
    });

    if (Object.entries(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({})
    }
        
    if (currentCount >= totalCount - 1) {
      nextSection();
      return;
    }
    setCurrentCount(currentCount + 1);
  };

  fieldPrefix += `.${currentCount}`;

  return (
    <>
      <SectionTitle>
        {itemName} #{currentCount + 1}
      </SectionTitle>
      {extraheading && <SectionHeading>{extraheading}</SectionHeading>}
      <SectionQuestions
        {...{ fieldPrefix, questions, updateAnswer, answers, errors }}
      />
      <NavButtons next={handleNextCount} />
    </>
  );
};

MultiSection.propTypes = {
  id: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  extraheading: PropTypes.string,
  questions: PropTypes.any.isRequired,
  updateAnswer: PropTypes.func.isRequired,
  answers: PropTypes.shape(),
  prompt: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  // nav
  nextSection: PropTypes.func.isRequired,
};

export default MultiSection;
